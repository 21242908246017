.board-epics-container {
  background-color: white;
  padding: 5px 10px;
  border-radius: 8px;
  margin: auto;
  margin-top: 20px;
  border: 1px solid transparent;
  max-width: 1200px;
  .board-epics-wrapper {
    height: 430px;
    position: relative;
    .board-epics-header {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 5px 0;
      margin-bottom: 15px;
      &-title {
        display: flex;
        align-items: center;
        h3 {
          margin-bottom: 0;
          vertical-align: middle;
        }
        button {
          color: white;
          margin-left: 10px;
          border: none;
        }
      }
      .inp-search {
        border-radius: 25px;
        border: 1px solid #5e6c84;
        padding: 6px 12px;
        input {
          padding: 2px 4px;
          outline: none;
          border: none;
        }
      }
    }
    .add-epic-wrapper {
      background-color: #f4f5f7;
      position: absolute;
      z-index: 1;
      top: 50px;
      width: 350px;
      left: 166px;
      padding: 20px 20px;
      border-radius: 2px;
      border: 1px solid #d7d69d;

      .epic-header {
        text-align: center;
      }
      .epic-form {
        &-title {
          &-description {
            p {
              word-wrap: break-word;
              padding: 6px 12px;
              border: 2px solid rgb(21, 40, 218);
              border-radius: 3px;
              font-weight: 500;
            }
          }
          &-input {
            input {
              width: 100%;
              height: 50px;
              padding: 6px 12px;
            }
            margin-bottom: 18px;
          }
        }
        &-color {
          .clearfix {
            .check-label {
              position: absolute;
              top: 4px;
              right: 11px;
              color: #fff;
              display: block;
            }
          }
        }
        &-lifeCycle {
          margin-top: 20px;
          &-item {
            margin-bottom: 20px;
            .datetime {
              width: 100%;
              padding: 4px 8px;
            }
          }
        }
        &-button {
          text-align: center;
          padding-top: 5px;
          .btn {
            border: 1px solid transparent;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            border-radius: 0.25rem;
            color: white;
            background-color: #0079bf;
            &:hover {
              outline: none;
              background-color: #219be2;
            }
          }
        }
      }
      .msg-error {
        color: red;
        margin-bottom: 10px;
      }
    }
    .table-epics {
      .item-active {
        background-color: #8084864b;
        transition: all 0.5s ease-in-out;
      }
      .item {
        border: none;
        height: 49px;
        text-align: left;
        vertical-align: middle;
        &.id {
          width: 220px;
          padding-right: 20px;
          span {
            display: block;
            overflow: hidden;
            width: 100%;
          }
        }
        &.name {
          width: 214px;
          span {
            width: 200px;
            display: block;
            overflow: hidden;
          }
        }
        &.number {
          text-align: center;
          width: 61px;
        }
        &.progress-percentage {
          padding-right: 40px;
          .progress-container {
            width: 100%;
            align-items: center;
            display: flex;
            span {
              margin-left: 5px;
              color: var(--ds-text-subtle, #5e6c84);
              font-size: 11px;
              width: 50px;
              vertical-align: middle;
            }
            .container {
              padding: 0;
              margin: 0;
              height: 8px;
              flex: 0 0 80%;
              background-color: rgb(143, 149, 153);
            }
          }
        }
        &.actions {
          button {
            color: white;
            border: none;
          }
        }
        &.theme {
          text-align: center;
          .themeColor {
            margin: auto;
            width: 15px;
            height: 15px;
            border-radius: 50%;
          }
        }
      }
    }
    .rows-num {
      position: absolute;
      left: 5px;
      right: 0;
      bottom: 60px;
    }
    .tableEpicsFooter {
      padding: 8px 0px;
      width: 100%;
      font-weight: 500;
      text-align: left;
      font-size: 16px;
      color: #2c3e50;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-right: 20px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .num-records {
      margin-right: 18px;
      font-weight: normal;
      font-size: 12px;
      width: 170px;
    }
    .btn-container {
      display: flex;
    }
    .button {
      border: none;
      padding: 7px 14px;
      border-radius: 50%;
      cursor: pointer;
      margin-right: 4px;
      margin-left: 4px;
      width: 38px;
      height: 38px;
      &:hover {
        color: #2c3e50;
      }
      &.activeButton {
        color: white;
        background-color: #0079bf;
      }

      &.inactiveButton {
        color: white;
        background-color: var(--ds-text-subtle, #c4cfe4);
      }
      &.prev-next {
        padding: 0;
        background-color: #c7ceda;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
      }
    }
  }
}
