.Login {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.formm{
    position: relative;
    line-height: 40px;
    
    width: 500px;
    box-shadow: 0 0 10px rgb(196, 196, 196);
    padding: 1em;
    // border: 2px solid #b4aeae;
    border-radius: 0.5em;
    background-color: #fff;

    @media only screen and (max-width: 600px) {
        width: 90%;
    }
    h2{
        text-align: center;
    }
}
.form{
    margin: 20px 20px;
}
.form-group{
    margin-bottom: 10px;
}
.tilte {
    font-weight: 600;
}
.btn-logo{
    // margin-top: 50px;
    position: relative;
    text-align: center;
    align-items: center;
    background-color: inherit;
    border: none;
    background-image: initial;
    width: 100%;
    text-align: center;

    @media only screen and (max-width: 600px) {
        margin-top: 0px;
    }

    img{
        width: 240px;
        margin-bottom: 30px;
    }
}
.header h1{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #282d3d;
    margin-bottom: 40px;
    font-weight:700;
}

.btn-danger{
    width: 100%;
    background-color: $baseColor !important;
    border-color: $baseColor !important;
}

.text-error {
    color: red;
    padding: 0;
    margin: 0;
    
}