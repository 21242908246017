* {
  user-select: auto;
}
.clearfix::after {
  clear: both;
  content: "";
  display: table;
}

.card-module-title {
  margin: 0 0 4px 40px;
  padding: 8px 0;
  position: relative;
}

.tickets-detail {
  align-items: flex-start;
  background-color: #0000007a;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;

  &_wrapper {
    min-height: calc(100vh - 96px);
    min-height: calc(var(--vh, 1vh) * 100 - 96px);

    margin: auto;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: #f4f5f7;
    border-radius: 2px;
    position: relative;
    width: 768px;
    z-index: 25;

    @media only screen and (max-width: 600px) {
      width: 100vw;
    }

    .tickets-detail-bgAttachment {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgb(172, 175, 172);
      cursor: pointer;
      img {
        &.svg,
        &.gif {
          background-color: white;
          height: 160px;
          width: auto;
        }
      }
      &:hover {
        opacity: 0.9;
      }
    }
  }
}
.close-ticket {
  z-index: 111;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  overflow: hidden;
  margin: 7px 0px 5px 8px;
}

.close-ticket a:hover {
  background-color: rgba(110, 105, 105, 0.2);
}

.close-ticket a {
  padding: 4px;
  transition: background-color 85ms, color 85ms;
  border-radius: 50%;
  overflow: hidden;
}

.ticket-header {
  width: 768px;
  max-width: 100%;
  min-height: 32px;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #f4f5f7;

  &_icon {
    padding: 10px 7px;
    width: 40px;

    svg {
      width: 25px !important;
    }
  }

  &_title {
    margin: 0 0;
    padding: 8px 0 0;
    width: 100%;
  }
}

.card-detail {
  &_header {
    width: 100%;
    background-color: #f4f5f7;
    border: none;
    display: inline-block;
    box-shadow: none;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    min-height: 32px;
    margin: -4px -8px;
    padding: 4px 8px;
    resize: none;
    overflow: hidden;

    &:focus {
      background-color: #fff;
      box-shadow: inset 0 0 0 2px $blue;
      outline: none;
    }
  }

  &_data {
    margin: 8px 0 0 40px;
  }

  &_item {
    display: block;
    float: left;
    margin: 0 8px 8px 0;
    max-width: 100%;
    &.epic {
      .epic-title {
        font-size: 14px;
        font-weight: 600;
        overflow: hidden;
        display: block;
        color: rgb(29, 25, 25);
        padding: 5px 10px;
        border-radius: 3px;
      }
    }
    &_header {
      color: #5e6c84;
      display: block;
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
      margin: 0 8px 4px 0;
    }

    &_member {
      border-radius: 25em;
      color: #172b4d;
      cursor: pointer;
      display: block;
      float: left;
      height: 32px;
      margin: 0 4px 4px 0;
      overflow: visible;
      position: relative;
      text-decoration: none;
      width: 32px;
      z-index: 0;

      &_avatar {
        border-radius: 25em;
        width: 32px;
        height: 32px;
      }
    }

    &_label {
      border-radius: 3px;
      cursor: pointer;
      box-sizing: border-box;
      display: block;
      float: left;
      font-weight: 600;
      height: 32px;
      line-height: 32px;
      margin: 0 4px 4px 0;
      min-width: 40px;
      padding: 0 12px;
      width: auto;
      background-color: $blue;

      & span {
        color: #fff;
      }
    }
    &_add-button {
      background-color: #091e420a;
      border: none;
      box-shadow: none;
      border-radius: 50%;
      cursor: pointer;
      float: left;
      margin: 0 8px 8px 0;

      &:hover {
        background-color: #091e4214;
        color: #42526e;
      }

      & span {
        font-size: 12px;
        line-height: 32px;
        padding: 10px;
        color: #42526e;
      }
    }
  }

  &_description,
  &_attachment,
  &_pullrequests {
    position: relative;
    & span {
      position: absolute;
      top: 7px;
      bottom: 20px;
    }

    &_title {
      position: relative;

      & h3 {
        display: inline-block;
        font-size: 16px;
        margin: 0;
        min-height: 18px;
        min-width: 40px;
        width: auto;
        color: #172b4d;
      }
    }
  }
}
.card-detail_checklist {
  margin-top: 50px;
  position: relative;
  & span {
    position: absolute;
    top: 7px;
    bottom: 20px;
  }

  &_title {
    position: relative;

    & h3 {
      display: inline-block;
      font-size: 16px;
      margin: 0;
      min-height: 18px;
      min-width: 40px;
      width: auto;
      color: #172b4d;
    }
  }
}
.checklist {
  &-progress {
    margin-bottom: 6px;
    position: relative;
    &-percentage {
      color: var(--ds-text-subtle, #5e6c84);
      font-size: 11px;
      left: 0;
      line-height: 10px;
      position: absolute;
      text-align: center;
      top: -1px;
      width: 32px;
    }
    &-bar {
      background-color: #091e4214;
      border-radius: 4px;
      height: 8px;
      margin: 0 0 0 40px;
      position: relative;
      .container {
        padding: 0;
        margin: 0;
        height: 8px;
        transition-duration: 0.14s;
        transition-property: width, background;
        transition-timing-function: ease-in;
      }
    }
  }
  &-items-list {
    padding: 0;
    .checklist-item {
      margin-bottom: 2px;
      display: flex;
      &-checkbox {
        input {
          background-color: #fafbfc;
          border-radius: 2px;
          cursor: pointer;
          margin: 8px 12px 0 2px;
          transition: all 0.2s ease-in-out;
          height: 16px;
          width: 16px;
          box-shadow: inset 0 0 0 2px #dfe1e6;
          &.complete {
            background-color: $blue;
            box-shadow: inset 0 0 0 2px $blue;
          }
        }
      }
      &-content {
        margin-left: 0px;
        flex: 1;
        display: flex;
        position: relative;
        justify-content: space-between;
        .item-title {
          display: flex;
          align-items: center;
          flex: 0 0 83.33%;
          position: relative;
          min-height: 35px;
          span {
            word-wrap: break-word;
            margin-bottom: 0;
            margin-right: 25px;
            font-size: 14px;
            border: none;
            background-color: inherit;
            font-weight: 400;
            flex: 1;
            overflow: hidden;
            width: 414px;
            max-width: 80vw;
          }
          &-edit {
            input {
              margin-right: 25px;
              font-size: 14px;
              width: 414px;
              max-width: 80vw;
              padding: 2px 5px;
            }
            .btn-edit-item {
              padding: 6px 12px;
              margin: 8px 0;
              border: none;
              border-radius: 3px;
              &.save {
                margin-right: 7px;
                color: white;
                background-color: $blue;
              }
              &.save:hover {
                outline: none;
                background-color: $blue;
                transform: translate(0, -2px);
              }
              &.cancel:hover {
                transform: translate(0, -2px);
              }
              span {
                margin: 0;
              }
            }
          }
          .complete {
            text-decoration: line-through;
            color: var(--ds-text-subtle, #5e6c84);
          }
        }
      }
      .item-btn-delete {
        display: none;
      }
      &:hover {
        background-color: #091e4214;
        transition: all 0.8s ease;
        -webkit-transition: all 0.8s ease;
        .item-btn-delete {
          display: block;
          position: absolute;
          top: 2px;
          right: 2px;
          .btn {
            padding: 6px 12px;
            color: black;
            border: none;
            border-radius: 3px;
            &:hover {
              background-color: rgba(0, 0, 0, 0.2);
              opacity: 0.7;
              transform: translate(0, -2px);
            }
          }
        }
      }
    }
  }
  &-new-item {
    margin-left: 40px;
    .btn-new-item {
      padding: 6px 12px;
      margin: 8px 0;
      border-radius: 3px;
      border: none;
      background-color: var(--ds-background-neutral, #091e420a);
      &.add {
        margin-right: 7px;
        color: white;
        background-color: $blue;
      }
      &.add:hover {
        outline: none;
        background-color: $blue;
        transform: translate(0, -2px);
      }
      &.cancel:hover {
        transform: translate(0, -2px);
      }
    }
    .btn-new-item:hover {
      opacity: 0.7;
      background-color: rgba(0, 0, 0, 0.2);
    }
    &-form {
      input {
        padding: 8px 12px 9px;
        font-size: 14px;
        width: 100%;
      }
    }
  }
}
.label-button {
  border-radius: 3px;
}

.ticket-main {
  float: left;
  margin: 0;
  min-height: 24px;
  padding: 0 8px 8px 16px;
  position: relative;
  width: 580px;
  z-index: 1;

  @media only screen and (max-width: 600px) {
    width: 100%;
    padding-left: 8px;
  }
}

.ticket-sidebar {
  // float: right;
  padding: 0 8px 8px 16px;
  width: calc(100% - 580px);
  // z-index: 1;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }

  & h3 {
    color: #5e6c84;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    margin: 0;
  }
  .ticket-private {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    span {
      line-height: 100%;
    }
    input {
      margin-right: 5px;
      background-color: #fafbfc;
      border-radius: 2px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      height: 16px;
      width: 16px;
      box-shadow: inset 0 0 0 2px $blue;
    }
    span {
      color: #5e6c84;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

.button-link {
  background-color: #091e420a;
  border: none;
  border-radius: 3px;
  padding: 6px 12px;
  display: block;
  height: 32px;
  text-align: left;
  overflow: hidden;
  width: 160px;
  margin-top: 8px;

  &_icon {
    font-size: 16px;
    line-height: 20px;
    color: #42526e;
    margin: 0 8px 0 -6px;
  }
  &_text {
    color: #172b4d;
  }
  &:hover {
    background-color: #091e4214;
  }
}

.ticket-labels-btn {
  position: relative;
  // z-index: 999;
}

.ticket-label {
  background-color: #fff;
  border-radius: 3px;
  overflow: hidden;
  z-index: 9999;
  width: 304px;
  position: absolute;
  justify-content: center;
  left: 0;
  top: 38px;
  overflow: hidden;
}

.label-modal {
  &_header {
    position: relative;
    height: 40px;
    margin-bottom: 8px;
    text-align: center;

    &_title {
      position: relative;
      border-bottom: 1px solid #091e4221;
      color: #5e6c84;
      display: block;
      line-height: 40px;
      padding: 0 32px;
      margin: 0 12px;
      white-space: nowrap;
    }
    &_close-btn {
      position: absolute;
      top: 0;
      right: 0;
      padding: 8px 12px 10px 8px;
      border: none;
      background-color: #fff;
      color: #6b778c;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
    &_back-btn {
      position: absolute;
      z-index: 90;
      top: 0;
      left: 0;
      padding: 8px 12px 10px 8px;
      border: none;
      background-color: #fff;
      color: #6b778c;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
  }

  &_content {
    max-height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 12px 12px;

    & input {
      margin: 4px 0 8px;
      padding: 8px 12px;
      width: 100%;
      border: none;
      border-radius: 3px;
      box-shadow: inset 0 0 0 2px #dfe1e6;
      background-color: #fafbfc;
      &:focus {
        background-color: #fff;
        box-shadow: inset 0 0 0 2px $blue;
        outline: none;
      }
    }
    &_title {
      color: #5e6c84;
      font-size: 12px;
      font-weight: 700;
      display: block;
    }
    &_section {
      margin-top: 8px;
      & h4 {
        color: #5e6c84;
        font-size: 14px;
        font-weight: 600;
      }
      &_create-btn {
        margin: 4px 0 0px;
        padding: 8px 12px;
        width: 100%;
        border: none;
        border-radius: 3px;
        background-color: #ebf0f3cb;
      }
    }
  }
}
.edit-btn {
  display: flex;
  justify-content: space-between;
}
.create-btn {
  border-radius: 3px;
  border: none;
  font-weight: 400;
  padding: 6px 12px;
  height: 32px;
  width: 80px;
  color: #fff;
  background-color: $blue;
  margin-top: 12px;
}
.delete-btn {
  border-radius: 3px;
  border: none;
  font-weight: 400;
  padding: 6px 12px;
  height: 32px;
  width: 80px;
  color: #fff;
  background-color: #b04632;
  margin-top: 12px;
}

.color-label {
  float: left;
  width: 48px;
  height: 32px;
  margin: 6px 8px 0 0;
  border-radius: 4px;
  position: relative;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.check-label {
  position: absolute;
  top: 4px;
  right: 17px;
  color: #fff;
  display: block;
}

.labels-list {
  padding: 4px 0 8px;
  margin: 0;
  list-style: none;
  &_detail {
    display: flex;
    width: 100%;
    margin-bottom: 5px;

    &_color {
      width: 100%;
      height: 32px;
      border-radius: 3px;
      position: relative;

      & h4 {
        color: #fff;
        font-size: 16px;
        padding: 6px 12px;
      }
    }
  }
}
.labels-list-edit {
  background-color: transparent;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  margin-left: 3px;
  cursor: pointer;
  &:hover {
    background-color: rgba(9, 30, 66, 0.0784313725);
  }
}
.ticket-members-btn {
  position: relative;
}
.ticket-member {
  margin-top: 5px;
  // height: 400px;
  width: 304px;
  background-color: white;
  overflow: hidden;
  position: absolute;
  z-index: 100;
  border-radius: 5px;
  &-header {
    height: 40px;
    margin-bottom: 8px;
    position: relative;
    text-align: center;
    span {
      border-bottom: 1px solid #091e4221;
      box-sizing: border-box;
      color: #5e6c84;
      display: block;
      line-height: 40px;
      margin: 0 12px;
      overflow: hidden;
      padding: 0 32px;
      position: relative;
      text-overflow: ellipsis;
      white-space: nowrap;
      z-index: 1;
    }
    &-close-btn {
      position: absolute;
      top: 0;
      right: 0;
      padding: 8px 12px 10px 8px;
      border: none;
      background-color: #fff;
      color: #6b778c;
      opacity: 0.7;
      z-index: 1;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }
  &_content {
    padding: 0 12px 12px;
    overflow: auto;
    &_member {
      ul {
        h6 {
          margin-top: 10px;
          margin-bottom: 0;
        }
        li {
          button {
            img {
              width: 28px;
              height: 28px;
              border-radius: 50%;
              margin-right: 10px;
            }
            border: none;
            width: 100%;
            text-align: left;
            background-color: inherit;
            padding: 4px 4px 0;
            line-height: 32px;
            border-radius: 3px;
            overflow: hidden;
            position: relative;
            .member_check {
              background-clip: content-box;
              background-origin: content-box;
              // padding: 11px;
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
            }
          }
          button:hover {
            color: blue;
          }
          a {
            img {
              width: 25px;
              height: 25px;
            }
            border: none;
            width: 100%;
            text-align: left;
            background-color: inherit;
            padding: 4px 4px 0;
            line-height: 32px;
            border-radius: 3px;
            overflow: hidden;
            position: relative;
            .member_check {
              background-clip: content-box;
              background-origin: content-box;
              // padding: 11px;
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
            }
          }
        }
      }
    }
  }
}
.ticket-epics-btn {
  position: relative;
}
.ticket-attachments {
  margin-top: 5px;
  width: 304px;
  background-color: white;
  overflow: hidden;
  position: absolute;
  z-index: 100;
  border-radius: 5px;
  &-header {
    height: 40px;
    margin-bottom: 8px;
    position: relative;
    text-align: center;
    span {
      border-bottom: 1px solid #091e4221;
      box-sizing: border-box;
      color: #5e6c84;
      display: block;
      line-height: 40px;
      margin: 0 12px;
      overflow: hidden;
      padding: 0 32px;
      position: relative;
      text-overflow: ellipsis;
      white-space: nowrap;
      z-index: 1;
    }
    &-close-btn {
      position: absolute;
      top: 0;
      right: 0;
      padding: 8px 12px 10px 8px;
      border: none;
      background-color: #fff;
      color: #6b778c;
      opacity: 0.7;
      z-index: 1;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }
  &-content {
    padding: 0 12px 12px;
    overflow: auto;
    position: relative;
    &-upload {
      padding: 4px 2px;
      position: relative;
      label {
        position: absolute;
        margin: 2px 0 0 2px;
      }
      input {
        width: 100%;
        opacity: 0;
        z-index: 2;
        margin: 0;
        padding: 0;
        cursor: pointer;
      }
      &:hover {
        background-color: #d6d6daf6;
        border-radius: 3px;
      }
    }
    &-files {
      margin-bottom: 10px;
      .file-upload-item {
        position: relative;
        margin-bottom: 8px;
        .nameFileUpload {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          width: 250px;
        }
        .btn-delete-file {
          position: absolute;
          right: 0;
          top: 0;
          cursor: pointer;
        }
      }
    }
    .btn-addFile {
      padding: 6px 12px;
      margin: 8px 0;
      border-radius: 3px;
      border: none;
      color: white;
      background-color: $blue;
      &:hover {
        opacity: 0.9;
      }
    }
  }
}
.ticket-epics {
  margin-top: 5px;
  width: 304px;
  background-color: white;
  overflow: hidden;
  position: absolute;
  z-index: 100;
  border-radius: 5px;
  &-header {
    height: 40px;
    margin-bottom: 8px;
    position: relative;
    text-align: center;
    span {
      border-bottom: 1px solid #091e4221;
      box-sizing: border-box;
      color: #5e6c84;
      display: block;
      line-height: 40px;
      margin: 0 12px;
      overflow: hidden;
      padding: 0 32px;
      position: relative;
      text-overflow: ellipsis;
      white-space: nowrap;
      z-index: 1;
    }
    &-close-btn {
      position: absolute;
      top: 0;
      right: 0;
      padding: 8px 12px 10px 8px;
      border: none;
      background-color: #fff;
      color: #6b778c;
      opacity: 0.7;
      z-index: 1;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }
  &-content {
    padding: 0 12px 12px;
    overflow: auto;
    &-epic {
      ul {
        width: 100%;
        padding: 0;
      }
      .epic-item {
        padding: 5px 10px;
        border-radius: 3px;
        // border: 1px solid #b7b8bb;
        margin-bottom: 5px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        .epic-name {
          display: block;
          overflow: hidden;
          width: 200px;
          font-size: 14px;
          font-weight: 600;
          color: rgb(29, 25, 25);
          // padding: 6px 12px;
        }
        .check-epic {
          position: absolute;
          right: 5px;
          top: 3px;
        }
      }
    }
  }
}

.ticket-description {
  margin-left: 40px;
  margin-bottom: 24px;

  @media only screen and (max-width: 600px) {
    margin-left: 0;
  }
  .des-edit {
    margin: 0;
    .des-unedit {
      min-height: 56px;
      padding: 8px 12px;
      border-radius: 5px;
      text-align: start;
      z-index: 100;
      @media only screen and (max-width: 600px) {
        padding: 2px 4px;
      }
    }
    .des-editable {
      padding-bottom: 9px;
      width: 100%;
      position: relative;
      outline: none !important;
      border: 1px solid #bebebe;
      padding: 8px 12px;
      border-radius: 5px;
      outline: none !important;
      textarea {
        min-height: 120px;
        background-color: white;
        width: 100%;
        border-color: #091e4221;
        padding: 8px 12px;
        line-height: 20px;
        border: none;
        border-radius: 3px;
      }
      .textarea:focus {
        outline: none !important;
        border: 1px solid red;
        box-shadow: 0 0 10px #719ece;
      }
      @media only screen and (max-width: 600px) {
        padding: 5px 8px;
      }
    }
  }
  .des-control {
    clear: both;
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    justify-content: space-between;
    .des-button {
      border: none;
      padding: 6px 12px;
      border-radius: 3px;
      display: inline-flex;
      color: white;
    }
    .des-button:hover {
      opacity: 0.8;
    }
    .des-save,
    .des-preview {
      background-color: $blue;
    }
    .des-cancel {
      margin-left: 5px;
      background-color: #698785;
    }
  }
  .codex-editor__redactor {
    padding-bottom: 20px !important;
    margin-right: 0;
    * {
      border-color: #bebebe !important;
    }
    .tc-table {
      border-left: 1px solid;
    }
    .inline-code {
      background-color: #e8e8eb;
    }
    .cdx-checklist__item-checkbox {
      border-radius: 0;
    }
    textarea {
      border: 1px solid #bebebe;
      overflow: auto;
    }
    .ce-code__textarea {
      background: transparent;
      min-height: 0;
      color: #000d24 !important;
    }
    .textarea_readonly {
      overflow-y: hidden;
      resize: none;
    }
    .cdx-checklist__item {
      align-items: center;
    }
    .cdx-checklist__item-checkbox {
      width: 16px;
      height: 16px;
    }
    .cdx-checklist__item-checkbox::after {
      top: 4px;
      left: 3px;
    }
  }
  button {
    border: none;
    margin-top: 16px;
    padding: 6px 12px;
    border-radius: 3px;
    display: inline-flex;
    color: white;
    background-color: $blue;
  }
}
.ticket-attachment-list {
  position: relative;
  .attachment-thumbnail {
    border-radius: 3px;
    margin: 0 0 8px;
    min-height: 80px;
    margin-left: 40px;
    // overflow: hidden;
    &.processing {
      justify-content: center;
      align-items: center;
      display: flex;
      background-color: rgba(9, 30, 66, 0.0392156863);
      .center {
        display: flex;
        justify-content: center;
        align-items: center;
        .processing {
          margin-left: 5px;
          color: var(--ds-text-subtle, #5e6c84);
        }
        .wave {
          width: 4px;
          height: 20px;
        }
      }
    }
    position: relative;
    &-details {
      box-sizing: border-box;
      cursor: pointer;
      margin: 0;
      min-height: 87px;
      padding: 8px 8px 8px 128px;
      span {
        color: var(--ds-text-subtle, #5e6c84);
      }
      &-name {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        .nameFile {
          color: black !important;
          font-weight: 700;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
        .icon {
          margin-left: 5px;
        }
      }
      &-options {
        margin-bottom: 3px;
        display: block;
        position: relative;
        .pop-edit-name-file {
          position: absolute !important;
          right: -150px;
          margin-top: 5px;
          background-color: white;
          overflow: hidden;
          position: absolute;

          z-index: 100;
          border-radius: 5px;
          box-shadow: var(
            --ds-shadow-overlay,
            0 8px 16px -4px #091e4240,
            0 0 0 1px #091e4214
          );
          &-header {
            height: 40px;
            margin-bottom: 8px;
            position: relative;
            text-align: center;
            span {
              border-bottom: 1px solid #091e4221;
              box-sizing: border-box;
              color: #5e6c84;
              display: block;
              line-height: 40px;
              margin: 0 12px;
              overflow: hidden;
              padding: 0 32px;
              position: relative;
              text-overflow: ellipsis;
              white-space: nowrap;
              z-index: 1;
            }
            &-close-btn {
              position: absolute;
              top: 0;
              right: 0;
              padding: 8px 12px 10px 8px;
              border: none;
              background-color: #fff;
              color: #6b778c;
              opacity: 0.7;
              z-index: 1;
              cursor: pointer;
              &:hover {
                opacity: 1;
              }
            }
          }
          &-content {
            padding: 0 12px 12px;
            overflow: auto;
            input {
              margin: 4px 0 8px;
              padding: 8px 12px;
              width: 100%;
              border: none;
              border-radius: 3px;
              box-shadow: inset 0 0 0 2px #dfe1e6;
              &:focus {
                box-shadow: inset 0 0 0 2px $blue;
                outline: none;
              }
            }
            .btn {
              border: none;
              padding: 6px 24px;
              border-radius: 3px;
              color: white;
              background-color: $blue;
            }
          }
        }
      }
      &-cover {
        span {
          align-items: center;
        }
      }
      .thumb-icon {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        height: 87px;
        width: 112px;
        object-fit: cover;
        border-radius: 0;
        &.docs,
        &.video,
        &.other {
          background-color: var(--ds-background-neutral, #091e420a);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .opt {
        text-decoration: underline;
      }
      &:hover {
        background-color: rgba(9, 30, 66, 0.0392156863);
      }
    }
  }
  .show-more-attachments,
  .hide-attachments {
    margin: 12px 0 8px 40px;
    cursor: pointer;
    .btn-view {
      border-radius: 3px;
      color: var(--ds-text-subtle, #5e6c84);
      display: block;
      margin: 2px 0;
      padding: 6px 0px;
      text-decoration: underline;
      user-select: none;
      &:hover {
        text-decoration: none;
        background-color: rgb(227, 231, 231);
      }
    }
  }
  .btn-add-attach {
    padding: 6px 12px;
    margin: 8px 0 8px 40px;
    border-radius: 3px;
    border: none;
    background-color: var(
      --ds-background-neutral,
      rgba(9, 30, 66, 0.0392156863)
    );
    &:hover {
      opacity: 0.7;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  .pop-attach-list {
    position: absolute;

    box-shadow: var(
      --ds-shadow-overlay,
      0 8px 16px -4px #091e4240,
      0 0 0 1px #091e4214
    );
    bottom: 45px;
    margin-left: 40px;
  }
}

.input-patse {
  width: 100px;
  padding: 8px;
  margin: 10px;
  display: none;

  @media  only screen and (max-width: 600px) {
    display: block;
  }
}

.ce-paragraph {
  overflow: auto;
}

.attachment-view {
  bottom: 0;
  left: 0;
  margin: 0 auto;
  position: fixed;
  right: 0;
  top: 0;
  transition: transform 0.15s;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  z-index: 200;
  background-color: #000000b3;

  &-container {
    bottom: 0;
    left: 24px;
    position: absolute;
    right: 24px;
    top: 0;
    z-index: 100;
    box-sizing: border-box;
    padding: 40px 24px 140px;
    .btn-close-attach-view {
      position: absolute;
      color: var(--ds-icon, #42526e);
      right: 0;
      transition: transform 0.3s, opacity 0.4s;
      top: 20px;
      z-index: 1000;
      cursor: pointer;
      &:hover {
        color: white;
        border-radius: 50%;
        background-color: #e9e0e03d;
        transform: translateX(5%) scale(1.2);
      }
    }
    .attachment-view-wrapper {
      width: 100%;
      height: 100%;
    }
    .item-view {
      display: none;
      text-align: center;

      &.active {
        width: 100%;
        height: 100%;
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      video,
      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 3px;
        &.svg,
        &.gif {
          background-color: white;
        }
      }
      .txt {
        background-color: white;
      }
      .item-view-download {
        color: var(--ds-text-inverse, #fff);
        font-size: 18px;
        font-weight: 700;
        &-btn {
          border-radius: 3px;
          box-sizing: border-box;
          color: var(--ds-text-inverse, #fff);
          display: block;
          margin: 8px auto;
          max-width: 250px;
          padding: 16px 24px;
          text-decoration: underline;
          cursor: pointer;
          &:hover {
            background-color: #e9e0e03d;
          }
        }
      }
    }
  }
  &-overlay {
    color: white;
    z-index: 100;
    left: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    .attachment-view-details {
      text-align: center;
      .nameFile {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 520px;
      }
      .opt {
        margin: 0;
        display: flex;
        justify-content: center;
        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .btn-opt {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .buttn-slide {
      cursor: pointer;
      color: var(--ds-icon, #42526e);
      transition: transform 0.15s, opacity 0.4s;
      &:hover {
        color: white;
        transform: translateX(5%) scale(1.2);
      }
    }
  }
}

.ticket-sidebar_list_actions {
  margin-top: 10px;
}

.ticket-points {
  margin-top: 5px;
  width: 304px;
  background-color: white;
  overflow: hidden;
  position: absolute;
  z-index: 100;
  border-radius: 5px;
  &-header {
    height: 40px;
    margin-bottom: 8px;
    position: relative;
    text-align: center;
    span {
      border-bottom: 1px solid #091e4221;
      box-sizing: border-box;
      color: var#5e6c84;
      display: block;
      line-height: 40px;
      margin: 0 12px;
      overflow: hidden;
      padding: 0 32px;
      position: relative;
      text-overflow: ellipsis;
      white-space: nowrap;
      z-index: 1;
    }
    &-close-btn {
      position: absolute;
      top: 0;
      right: 0;
      padding: 8px 12px 10px 8px;
      border: none;
      background-color: #fff;
      color: #6b778c;
      opacity: 0.7;
      z-index: 1;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }
  &-content {
    padding: 0 12px 12px;
    overflow: auto;
    & input {
      position: relative;
      margin: 4px 0 8px;
      padding: 8px 12px;
      width: 100%;
      border: none;
      border-radius: 3px;
      box-shadow: inset 0 0 0 2px #dfe1e6;
      background-color: #fafbfc;
      &:focus {
        background-color: #fff;
        box-shadow: inset 0 0 0 2px $blue;
        outline: none;
      }
    }
    &-point {
      ul {
        width: 100%;
        padding: 0;
      }
      .point-item {
        // background-color: #75dab4;
        padding: 5px 10px;
        border-radius: 3px;
        border: 1px solid #b7b8bb;
        margin-bottom: 5px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        display: flex;
        justify-content: space-between;

        &:hover {
          background-color: #eaeaea;
        }

        .point-value {
          line-height: 25px;
        }

        .point-bonus-btn {
          border: none;
          padding: 2px 4px;
          border-radius: 3px;
          color: white;
          background-color: $blue;
          margin-left: 5px;
          opacity: 0.9;
          &:hover {
            opacity: 1;
          }
        }

        &.active {
          background-color: rgba(208, 208, 206, 0.615);
        }
      }
    }
  }
  .point-clear {
    position: absolute;
    border-radius: 3px;
    border: none;
    padding: 2px 6px;
    color: #fff;
    background-color: #b04632;
    right: 25px;
    top: 58px;
  }
}
.btn-pull-request{
  padding: 2px;
  border: none;
  margin-left: 7px;
  border-radius: 3px;
  color: white;
  background-color: #0079bf;
}

.ticket-content {
  display: flex;
  justify-content: space-between;

  flex-wrap: wrap;
}
.ticket-git-btn {
  position: relative;
  margin-bottom: 10px;
}
.ticket-id {
  display: flex;
  width: 100%;
  position: relative;
  margin-bottom: 10px;

  input {
    width: 85%;
    padding: 4px 8px;
    border: 1px solid #bebebe;
    border-radius: 3px;
    opacity: 0.9;
    cursor: pointer;
  }
  span {
    width: 15%;
    position: absolute;
    right: 0;
    top: 3px;
    cursor: pointer;
  }
}
.ticket-git {
  // height: 400px;
  width: 350px;
  background-color: white;
  overflow: hidden;
  position: absolute;
  z-index: 100;
  border-radius: 5px;
  @media only screen and (max-width: 1140px) {
    right: 0;
  }
  &-header {
    height: 40px;
    margin-bottom: 8px;
    position: relative;
    text-align: center;
    span {
      border-bottom: 1px solid #091e4221;
      box-sizing: border-box;
      color: #5e6c84;
      display: block;
      line-height: 40px;
      margin: 0 12px;
      overflow: hidden;
      padding: 0 32px;
      position: relative;
      text-overflow: ellipsis;
      white-space: nowrap;
      z-index: 1;
    }
    &-close-btn {
      position: absolute;
      top: 0;
      right: 0;
      padding: 8px 12px 10px 8px;
      border: none;
      background-color: #fff;
      color: #6b778c;
      opacity: 0.7;
      z-index: 1;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }
  &-content {
    padding: 0 12px 12px;
    overflow: auto;
    h6 {
      opacity: 0.9;
      font-weight: 450;
    }
    .ticket-git-switch {
      margin-bottom: 10px;
      padding-bottom: 0px;
      width: 100%;
      display: flex;
      .ticket-git-switch-branch-btn {
        width: 40%;
      }
      .ticket-git-switch-commit-btn {
        width: 60%;
      }
      .btn {
        padding: 1px 9px;
        border-radius: 0;
        border-bottom: 1px solid #bebebe;
        color: darkgrey;
        font-size: 15px;
      }
      .git-switch-active {
        border: 1px solid #bebebe;
        border-bottom: 1px solid white;
        font-weight: bolder;
        color: black;
      }
    }
    .ticket-git-branch-content {
      margin-bottom: 10px;
      .ticket-git-branch-control {
        position: relative;
        width: 100%;

        input {
          width: 100%;
          padding: 5px 8px;
          border: 1px solid #bebebe;
          border-radius: 3px;
          opacity: 0.9;
        }
        button {
          position: absolute;
          right: 0;
          top: -34px;
          padding-right: 0;
        }
      }
    }
  }
}
.ticket-git-pr {
  margin-left: 40px;
  margin-bottom: 24px;
  .pull-request-data {
    margin-bottom: 9px;
    &-header {
      display: flex;
      align-items: center;
      span {
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-color: $blue;
      }
      p {
        margin: 0;
        border-radius: 3px;
        font-size: 13px;
        padding: 2px 5px;
        background-color: #dddddd;
        cursor: pointer;
        // background-color: rgba(9, 30, 66, 0.0392156863);
      }
    }
    &-content {
      line-height: 20px;
      margin-left: 32px;
      margin-bottom: 3px;
      font-weight: 700;
      a {
        margin-left: 4px;
        color: $blue;
      }
      a:hover {
        background-color: rgba(9, 30, 66, 0.0392156863);
      }
    }
    &-state {
      margin-left: 35px;
      .pull-request-state {
        border-radius: 3px;
        margin: 0;
        margin-top: 2px;
        padding: 2px 5px;
        color: white;
      }
      .open {
        background-color: $darkGreen;
      }
      .merged {
        background-color: $purple;
      }
      .closed {
        background-color: $red;
      }
    }
  }
}
