.content-wrapper {
  height: calc(100vh - 44px);
  height: calc(var(--vh, 1vh) * 100 - 44px);
}

.home {
  width: 100vw;
  height: calc(100vh - 44px);
  height: calc(var(--vh, 1vh) * 100 - 44px);

  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-size: 40px;
    color: white;
  }

  .box {
    width: 100px;
    height: 100px;
    border: 1px solid white;
    color: orange;
  }

  &-shortcuts {
    margin: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 700px;
    height: 60vh;
    background-color: #0b2c3dcc;
    color: white;
    border-radius: 6px;
    box-shadow:  0 0 10px rgba(1, 2, 60, 0.653);
  }

  .quick-access {
    display: flex;
    margin-top: 15px;

    &_menuitem {
      margin: 10px;
      border: 1px solid;
      padding: 4px 10px;
      border-radius: 8px;
      text-align: center;

      a {
        &:hover {
          color: $orange;
        }
      }
    }
  }
}