.archive-header {
  background-color: rgba(11, 44, 61, 0.1803921569);
  position: relative;
  display: flex;
  height: 50px;
  padding: 6px 10px;
  position: relative;
  align-items: center;
  &-title {
    cursor: default;
    color: white;
    h5 {
      margin: 0;
    }
  }
  &-list {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
  }
}
