.CartComposer {
  margin-bottom: 10px;
  margin: 0 10px 7px 10px;
  border: none;
  border-radius: 4px;

  &-flex {
    &_bd {
      margin-top: 10px;
      width: 252;
      line-height: 1.5em;
      border-bottom: 1px solid #a6a8af;
      border-radius: 4px;
      margin-bottom: 7px;
      background-color: white;
      width: auto;
      overflow: hidden;
      padding: 6px 8px 2px;
      position: relative;
      z-index: 10;
      cursor: pointer;
      width: 100%;
      .ticket-private {
        z-index: 100;
        position: absolute;
        bottom: 4px;
        right: 4px;
        display: flex;
        align-items: center;
        span {
          line-height: 100%;
        }
        input {
          margin-right: 5px;
          background-color: #fafbfc;
          border-radius: 2px;
          cursor: pointer;
          transition: all 0.2s ease-in-out;
          height: 16px;
          width: 16px;
          box-shadow: inset 0 0 0 2px #0079bf;
        }
        span {
          color: #5e6c84;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
    &_save {
      display: flex;
    }
  }
}

.control-section {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;

  &_icon {
    border-radius: 3px;
    display: block;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;

    button.dropdown-btn {
      &:after {
        content: "";
        border: none;
      }
    }

    svg {
      margin-top: 6px;
    }

    &:hover {
      background-color: rgba(110, 105, 105, 0.2);
    }
  }
}

.cart-composer {
  width: 100%;
  overflow: hidden;
  border: none;
  outline: none;
}

.control-cc button {
  margin-right: 7px;
  color: white;
  background-color: #0079bf;
}

.control-cc button:hover {
  outline: none;
  margin-right: 5px;

  background-color: #2ea5eb;
}

.control-cc button:focus {
  outline: none;
}

.btn :focus {
  outline: none;
}
.list-addcart {
  &_b {
    display: none;
    cursor: pointer;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    border-radius: 3px;
    color: #5e6c84;
    display: flex;
    margin: 2px 0 8px 8px;
    padding: 4px 8px;
    position: relative;

    & :hover {
      border-radius: 3px;
      background-color: rgba(110, 105, 105, 0.2);
    }

    &_button {
      padding-top: 5px;
      align-items: center;
      height: 30px;
      width: 80%;
      button {
        background: none;
        pointer-events: none;
        margin-left: 3px;
        border: none;
        color: none;
      }
    }

    &_file {
      padding-top: 5px;
      height: 30px;
      text-align: center;
      align-items: center;
      width: 32px;
    }
  }
}
.input-enter-content-editable {
  padding: 0;
  text-align: left;
  background-color: inherit;
  border: none;
  padding-left: 10px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;

  &:focus {
    border: 2px solid #0079bf;
  }
}
