.board-filter {
    background-color: #fff;
    border-radius: 3px;
    width: 384px;
    justify-content: center;
    right: 0px;
    top: 98px;
    position: fixed;
    z-index: 9999;
    height: calc(100% - 99px);
  &_header {
    height: 40px;
    margin-bottom: 8px;
    text-align: center;

    &_title {
      border-bottom: 1px solid rgba(9, 30, 66, 0.1294117647);
      color: #5e6c84;
      display: block;
      line-height: 40px;
      padding: 0 32px;
      margin: 0 12px;
    }
    &_close-btn {
      position: absolute;
      top: 0;
      right: 0;
      padding: 8px 12px 10px 8px;
      border: none;
      background-color: #fff;
      color: #6b778c;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
    &_clear-filter {
      position: absolute;
      top: 0;
      left: 0;
      padding: 8px 12px 10px 8px;
      border: none;
      background-color: #fff;
      color: red;
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }
  }

  &_content {
    padding: 0 12px 12px;
    height: calc(100% - 44px);
    overflow-y: auto;

    &_section {
      margin-top: 8px;

      & .search-input {
        margin: 4px 0 8px;
        padding: 8px 12px;
        width: 100%;
        border: none;
        border-radius: 3px;
        box-shadow: inset 0 0 0 2px #dfe1e6;
        background-color: #fafbfc;
        &:focus {
          background-color: #fff;
          box-shadow: inset 0 0 0 2px #0079bf;
          outline: none;
        }
      }

      & h3 {
        color: #5e6c84;
        font-size: 12px;
        font-weight: 700;
        display: block;
      }

      & p {
        color: #5e6c84;
        font-size: 11px;
        line-height: 14px;
      }

      &_item {
        margin-bottom: 15px;
        & ul {
          padding: 0;
          margin: 0;
          & li {
            display: flex;
            padding: 8px;
            box-sizing: border-box;
            position: relative;

            // & span {
            //   margin-left: 12px;
            // }
            & label {
              margin-left: 12px;
              width: 100%;
              & :hover {
                cursor: pointer;
              }
            }
          }
        }
        &-wrapper {
          display: flex;
          width: 100%;
        }
        &_icon {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 50%;
          width: 24px;
          height: 24px;
          background-color: #ebecf0;
        }

        &_img {
          width: 30px;
          height: 30px;
          overflow: hidden;
          border-radius: 50%;
          
          & img {
            max-width: fit-content;
            width: auto;
            height: 100%;
          }
        }

        &_content {
          font-size: 14px;
          color: #5e6c84;
          margin-left: 12px;
        }
        &_dropdown {
          //   position: relative;
          margin: 4px 0 0;
          padding: 8px 12px;
          width: 100%;
          border: none;
          border-radius: 3px;
          box-shadow: inset 0 0 0 #dfe1e6;
          background-color: #fafbfc;
          margin-left: 12px;
          height: 40px;
          text-align: left;
          color: #5e6c84;
          &:focus {
            background-color: #fff;
            box-shadow: inset 0 0 0 2px #0079bf;
            outline: none;
          }
          &:hover {
            background-color: #ebecf0;
            cursor: pointer;
          }
          &_icon {
            position: absolute;
            font-size: 16px;
            right: 20px;
            bottom: 12px;
          }
        }
        &_labels {
          width: 100%;
        }
      }
    }
  }

  & .select-items {
    width: 100%;
  }
  & .list-items {
    border: 1px solid #d7dadf;
    background-color: #fff;
    width: 319px;
    border-radius: 3px;
    margin-left: 32px;
    margin-bottom: 10px;

    & ul {
      padding: 8px 0;
      & li {
        &:hover {
          background-color: #f1f1f8;
        }
      }
    }
  }
  & .epic-name {
    padding: 6px 12px;
    width: 100%;
  }
}
