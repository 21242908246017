.ticket-activity {
    margin-top: 20px;
    padding: 15px;
    width: 100%;

    &_icon {
        display: flex;
        width: 100%;
        justify-content: space-between;

        & h3 {
            display: inline-block;
            font-size: 16px;
            margin-left: 20px;
            min-height: 18px;
            min-width: 40px;

            color: #172b4d;
        }
    }

    & .checklist-new-items {
        & .btn-new-items {
            border-radius: 3px;
            border: none;
        }

        & button {
            height: 32px;
            width: 100px;
            background-color: #091e420a;
            border: none;

            &:hover {
                opacity: 0.7;
                background-color: rgba(0, 0, 0, 0.2);
            }
        }
    }

    & .toggle {
        position: relative;
        width: 580px;
        height: auto;
        background-color: #f4f5f7;
        right: 0;

        & .show-more-activities {
            text-decoration: line color style;
            height: 50px;
            width: 100%;
            color: rgb(110, 104, 104);
            width: 100%;
            padding: 10px;
            text-align: left;
            background-color: inherit;
            align-items: center;
            border: none;

            &:hover {
                color: #172b4d;
                background-color: rgb(235, 231, 231);
            }

            & p {
                margin: 0;
                text-decoration: underline;
            }

        }

    }

    & h3 {
        padding-top: 8px;
    }

    & span {
        padding-top: 4px;
    }

}