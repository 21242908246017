.slack-connection {
  align-items: center;
  background-color: #0000007a;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  top: 0;
  overflow-y: auto;
  position: fixed;
  width: 100%;
  z-index: 9999999999;

  &-wrapper {
    z-index: 9999999999;
    min-height: 45vh;
    width: 60vh;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: #f4f5f7;
    border-radius: 2px;
    overflow: auto;
    position: relative;
    z-index: 25;
    padding: 20px 0px 0px 24px;

    h5 {
      text-align: center;
    }
  }
  &-header {
    padding: 0px 24px 16px 0px;
    .header-close-btn {
      border-radius: 50%;
      height: 40px;
      margin: 4px;
      overflow: hidden;
      padding: 4px;
      position: absolute;
      right: 0;
      top: 0;
      transition: background-color 85ms, color 85ms;
      width: 40px;
      z-index: 2;
      border: none;
      background-color: inherit;
    }
    .header-close-btn:hover {
      background-color: rgba(110, 105, 105, 0.2);
    }
  }
  h6 {
    font-size: 16px;
  }
  &-select {
    padding: 0px 24px 20px 0px;

    // &-wrapper {
    //   display: flex;

    //   .slack-connection-select {
    //     display: flex;
    //     width: 80%;
    //     margin-right: 8px;
    //     width: 100%;

    .inputBox {
      width: 100%;
      padding: 5px 10px;
    }
    .selectBox {
      width: 100%;
    }
    // }
    // }
  }
  &-content {
    padding: 0px 24px 20px 0px;

    .slack-connection-list {
      ul:first-child {
        margin: 0;
        width: 100%;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .slack-connection-item {
          width: 49%;
          margin: 0 1px;
          padding: 5px 10px;
          border-radius: 3px;
          border: 1px solid #b7b8bb;
          margin-bottom: 5px;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          &.active {
            color: white;
            background-color: $lightBlue;
          }
          .check-slack {
            position: absolute;
            top: 5px;
            right: 5px;
            color: #fff;
            display: block;
          }
        }
        .slack-connection-item:hover {
          opacity: 0.7;
        }
      }
      .checkbox-moving {
        margin-top: 5px;
        h6 {
          text-align: center;
          font-size: 14px;
        }
        width: 100%;
        padding: 0;
        border: 1px solid #b7b8bb;
        padding: 5px;
        li {
          user-select: none;
          padding: 5px 10px;
          border-radius: 3px;
          border: 1px solid #b7b8bb;
          margin-bottom: 5px;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          position: relative;
          &.active {
            color: white;
            background-color: $lightBlue;
          }
          .check-slack {
            position: absolute;
            top: 5px;
            right: 5px;
            color: #fff;
            display: block;
          }
        }
      }
    }
  }
  &-submit {
    padding: 0px 24px 20px 0px;
    margin-top: 0px;

    .submit-btn {
      width: 100%;
    }
  }
}
