@mixin bgImage($image) {
  background: url($image);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-blend-mode: overlay;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flexVertical {
  display: flex;
  align-items: center;
}

@mixin responsive($width) {
  @media (max-width: $width) {
    @content;
  }
}

@mixin bgImgUrl($name) {
  background-image: url("../../public/" + $name);
}


@mixin bgImgUrlPublic($name) {
  background-image: url($name);
}
