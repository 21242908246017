.profile-popup {
  background-color: #fff;
  border-radius: 3px;
  width: 304px;
  justify-content: center;
  right: 10px;
  top: 43px;
  position: fixed;
  z-index: 9999;
  box-shadow: 0 0 8px $baseColor;

  &_header {
    height: 40px;
    margin-bottom: 8px;
    text-align: center;

    &_title {
      border-bottom: 1px solid rgba(9, 30, 66, 0.1294117647);
      color: #5e6c84;
      display: block;
      line-height: 40px;
      padding: 0 32px;
      margin: 0 12px;
    }
    &_close-btn {
      position: absolute;
      top: 0;
      right: 0;
      padding: 8px 12px 10px 8px;
      border: none;
      background-color: #fff;
      color: #6b778c;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
  }
  &_content {
    padding: 0 12px 12px;

    &_section {
      margin-top: 8px;
      width: 100%;

      &_item {
        display: block;
        width: 100%;
        padding: 6px 12px;
        position: relative;
        &:hover {
          background-color: #ebecf0;
          cursor: pointer;
          color: #333;
        }
      }
    }
  }
}
