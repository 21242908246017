.board-header {
  display: flex;
  height: 50px;
  padding: 6px 10px;
  position: relative;
  align-items: center;

  &_wrapper {
    align-items: center;
    display: flex;
    position: relative;

    // &_share button {
    //   color: white;
    //   background-color: #0079bf;
    // }
    &_user {
      color: #0b2c3d;
      font-size: 18px;
      font-weight: 700;
      line-height: 32px;
      padding: 0 12px;
      cursor: pointer;
      display: flex;
      &:hover {
        color: #000000;
      }
      .board-view-only {
        margin-left: 5px;
        display: flex;
        align-items: center;
        span {
          padding-left: 2px;
          line-height: 100%;
          color: #5e6c84;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }

    // &_share {
    //   position: relative;
    //   display: flex;
    //   .epics {
    //     margin-right: 20px;
    //   }
    // }
  }

  &_l {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;

    &_button {
      
      margin: 3px;

      & button {
        color: white;
        border: none;
        outline: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.sh {
  margin-right: 5px;
}

.users-board {
  align-items: center;
  background-color: #0000007a;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  top: 0;
  overflow-y: auto;
  position: fixed;
  width: 100%;
  z-index: 999999999;

  &_wrapper {
    z-index: 999999999;
    height: 45vh;
    width: 60vh;
    justify-content: center;
    align-items: center;
    color: black;
    background-color: #f4f5f7;
    border-radius: 2px;
    overflow: auto;
    position: relative;
    z-index: 25;
    padding: 20px 0px 0px 24px;

    h5 {
      text-align: center;
    }
  }

  &_header {
    padding: 0px 24px 16px 0px;
    .header-close-btn {
      border-radius: 50%;
      height: 40px;
      margin: 4px;
      overflow: hidden;
      padding: 4px;
      position: absolute;
      right: 0;
      top: 0;
      transition: background-color 85ms, color 85ms;
      width: 40px;
      z-index: 2;
      border: none;
      background-color: inherit;
    }
    .header-close-btn:hover {
      background-color: rgba(110, 105, 105, 0.2);
    }
  }

  &_invite {
    padding: 0px 24px 20px 0px;

    &_wrapper {
      display: flex;

      .input-container {
        display: flex;
        width: 80%;
        margin-right: 8px;
        width: 100%;

        .selectBox {
          width: 100%;
        }
      }

      .btn-add-member {
        font-size: 14px;
        width: 20%;
        color: white;
        height: 38px;
        margin: 0;
        width: unset;
        border: none;
        border-radius: 3px;

        line-height: 20px;
        padding: 4px 12px;
      }
    }
  }

  &_error {
    span {
      color: $red;
      font-size: 20px;
    }

    padding-bottom: 20px;
  }

  &_content_member {
    ul {
      margin: 0;
      padding: 0;

      h6 {
        margin: 0;
        padding: 10px 0;
        font-size: 16px;
      }

      li {
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-bottom: 10px;
        padding-right: 20px;

        img {
          width: 25px;
          height: 25px;
        }

        .btn {
          background-color: rgb(204, 204, 204);
          justify-content: end;
        }

        span {
          font-size: 15px;
          padding-left: 5px;
        }

        .member {
          color: #acc280;
        }

        .invitedMember {
          color: #ff9f1a;
        }
      }
    }
  }
}
